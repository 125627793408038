:root {
    --tac-background-image: none;
}
#tarteaucitronRoot * {
    box-sizing: border-box;
}
#tarteaucitronRoot {
    @include cabin12(400, #6c5b51fc);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    @include mq(768px,null) {
        @include cabin15(400, #6c5b51fc);
    }
    ol,
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    button {
        border: none;
        background-color: transparent;
        color: #6c5b51fc;
        &:focus-visible,
        &:focus {
            outline: none;
        }
    }
    .tac-wrap {
        padding: 15px 5px;
        border: 1px solid #6c5b51fc;
        @include mq(768px,null) {
            padding: 25px;
        }
    }
    .tac-left {
        padding: 15px 15px 0 15px;
        @include mq(768px,null){
            padding: 5px 80px 35px 0;
        }
        .tac-title {
            //@include font-size-lh(14, 14);
            font-weight: 700;
            text-transform: uppercase;
            @include mq(768px,null) {
                //@include font-size-lh(21, 21);
            }
        }
        .tac-subtitle {
            @include cabin20(700, #6c5b51fc);
            @include mq(768px,null) {
                @include cabin20(700, #6c5b51fc);
            }
        }
        p {
            margin: 15px 0 0 0;
        }
    }
    .tac-right {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 15px 15px 0 15px;
        @include mq(768px,null) {
            padding: 0 40px 0 0;
            &#tarteaucitronActions {
                padding-right: 0;
            }
        }
        > * {
            flex: 0 1 100%;
            @include mq(768px,null) {
                flex-basis: 140px;
                &#tarteaucitronAcceptAllPanel {
                    flex-basis: 160px;
                }
            }
        }
        button {
            //@include font-size-lh(14, 21);
            font-weight: 600;
            color: #6c5b51fc;
            margin: 5px 0;
            padding: 5px;
            border: 1px solid #6c5b51fc;
            cursor: pointer;
            transition: all 0.3s;
            @include mq(768px,null) {
                //@include font-size-lh(19, 29);
                margin: 0;
                padding: 8px;
            }
            &:hover {
                color: $white;
                background-color: #6c5b51fc;
            }
            &:last-child {
                color: $white;
                background-color: #6c5b51fc;
                &:hover {
                    color: #6c5b51fc;
                    background-color: $white;
                }
            }
        }
    }
    .tac-trigger {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 1000;
        &--link {
            width: 44px;
            height: 44px;
            border-radius: 74% 26% 37% 63% / 61% 26% 74% 39%;
            display: flex;
            justify-content: center;
            background: #6c5b51fc;
            padding: 0;
            border: 1px solid $white;
            i,
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                @include font-size-lh(20,20);
                color: $white;
            }
        }
    }
    .tarteaucitronWrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include mq(768px,null){
            align-items: center;
        }
    }
    .tarteaucitron-display-block {
        display: block;
    }
    .tarteaucitron-display-none {
        display: none;
    }
    #tarteaucitronBack {
        display: none;
        pointer-events: none;
        height: 100%;
        left: 0;
        background-color: #6c5b51fc;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2147483646;
        @include mq(768px,null) {
            display: none !important;
        }
    }
    #tarteaucitronAlertBig {
        position: fixed;
        bottom: 20px;
        left: 20px;
        z-index: 2147483645;
        width: calc(100% - 40px);
        max-width: 568px;
        &:focus-visible {
            outline: none;
        }
    }
    .tarteaucitronAlertBigWrapper {
        background-color: transparent;
        background-image: var(--tac-background-image);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 15px;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .tarteaucitronAlertLogo {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        @include mq(768px,null) {
            top: 25px;
            right: 25px;
            width: 80px;
            height: 80px;
        }
        img {
            width: 100%;
            height: auto;
            @include object(contain)
        }
    }
    #tarteaucitronDisclaimerAlert {
        background-color: rgba($white, 0.64);
        backdrop-filter: blur(40px) brightness(150%);
        display: block;
    }
    #tarteaucitron {
        position: fixed;
        top: inherit !important;
        right: 0;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
        max-width: 568px;
        display: none;
        z-index: 2147483647;
        overflow: hidden;
        padding: 0;
        a {
            color: #6c5b51fc;
        }
        #tarteaucitronInner {
            background-color: transparent;
            background-image: var(--tac-background-image);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 15px;
            width: 100%;
            height: 100%;
            position: relative;
        }
        #tarteaucitronContent {
            background-color: rgba($white, 0.64);
            backdrop-filter: blur(40px) brightness(150%);
            max-height: clamp(10px, 500px, calc(100vh - 40px));
        }
        #tarteaucitronScrollbarAdjust {
            display: flex;
            align-items: flex-end;
            padding: 0 10px;
            @include mq(768px,null) {
                padding: 0;
            }
            .tarteaucitronAsk {
                flex: 0 0 60px;
                position: relative;
                @include mq(768px,null) {
                    flex-basis: 80px;
                }
                .tarteaucitronServiceIpt {
                    + .toggle {
                        .toggle-inside {
                            left: 0;
                        }
                    }
                }
            }
            .tarteaucitronName {
                flex: 0 1 auto;
                padding-right: 40px;
                @include mq(768px,null) {
                    padding-right: 65px;
                }
            }
            .tarteaucitronH2 {
                @include cabin15(700, #6c5b51fc);
                @include mq(768px,null) {
                    @include cabin20(700, #6c5b51fc);
                }
            }
        }
        .tarteaucitronServiceIpt {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            z-index: 2;
            width: 60px;
            height: 31px;
            appearance: none;
            @include mq(768px,null) {
                width: 80px;
            }
            &.tarteaucitronAllow {
                right: 0;
                &.tarteaucitronIsSelected {
                    z-index: 1;
                    + .toggle {
                        .toggle-outside {
                            background-color: #6c5b51fc;
                        }
                        .toggle-inside {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='31' viewBox='0 0 33 31'%3E%3Cellipse cx='16.5' cy='15.5' fill='%23fff' rx='16.5' ry='15.5'/%3E%3Cpath fill='none' stroke='%2354A659' stroke-linecap='round' stroke-width='2.999' d='m10.333 14.645 5.056 6.315m7.964-11.959L15.39 20.957'/%3E%3C/svg%3E");
                            left: calc(100% - 33px) !important;
                        }
                    }
                }
            }
            &.tarteaucitronDeny {
                left: 0;
                &.tarteaucitronIsSelected {
                    z-index: 1;
                    + .toggle {
                        .toggle-outside {
                            background-color: $border-account;
                        }
                        .toggle-inside {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='31' viewBox='0 0 33 31'%3E%3Cellipse cx='16.5' cy='15.5' fill='%23fff' rx='16.5' ry='15.5'/%3E%3Cpath fill='none' stroke='%23939393' stroke-linecap='round' stroke-width='2.999' d='m21.022 11.001-7.964 9.058m.002-9.057 7.964 9.058'/%3E%3C/svg%3E");
                            left: 0;
                        }
                    }
                }
            }
        }
        .toggle {
            width: 60px;
            height: 31px;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include mq(768px,null) {
                width: 80px;
            }
        }
        .toggle-outside {
            display: inline-flex;
            width: 54px;
            height: 23px;
            border-radius: 17px;
            background-color: $border-account;
            transition: all 0.3s;
            @include mq(768px,null) {
                width: 72px;
            }
        }
        .toggle-inside {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='31' viewBox='0 0 33 31'%3E%3Cellipse cx='16.5' cy='15.5' fill='%23fff' rx='16.5' ry='15.5'/%3E%3Cpath fill='none' stroke='%23939393' stroke-linecap='round' stroke-width='2.999' d='m21.022 11.001-7.964 9.058m.002-9.057 7.964 9.058'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            width: 33px;
            height: 31px;
            display: inline-flex;
            position: absolute;
            top: 0;
            left: calc(50% - 33px / 2);
            transition: all 0.3s;
        }
        #tarteaucitronServices {
            border: 1px solid $border-account;
            padding: 0 10px;
            margin: 10px 0 15px 0;
            #tarteaucitronInfo {
                display: block;
                position: relative;
                padding: 15px 0;
                font-size: 12px;
                #tarteaucitronPrivacyUrlDialog {
                    padding: 0;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            #tarteaucitronServices_mandatory {
                .tarteaucitronAllow {
                    opacity: 1;
                }
            }
            .tarteaucitronH1 {
                font-size: 18px;
                font-weight: 700;
                color: #6c5b51fc;
                display: block;
            }
            .tarteaucitronH3 {
                display: block;
                @include cabin14(700, #6c5b51fc);
                @include mq(768px,null) {
                    @include cabin17(700, #6c5b51fc);
                }
            }
            .tarteaucitronLine {
                overflow: hidden;
                padding: 8px 0;
                display: flex;
                align-items: center;
                @include mq(768px,null) {
                    align-items: flex-end;
                }
                &.tarteaucitronMainLine {
                    padding: 0;
                }
                .tarteaucitronAsk {
                    flex: 0 0 60px;
                    position: relative;
                    @include mq(768px,null) {
                        flex-basis: 80px;
                    }
                }
                &.tarteaucitronIsDenied {
                    .tarteaucitronServiceIpt.tarteaucitronDeny {
                        z-index: 1;
                    }
                    .toggle-outside {
                        background-color: $border-account;
                    }
                    .toggle-inside {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='31' viewBox='0 0 33 31'%3E%3Cellipse cx='16.5' cy='15.5' fill='%23fff' rx='16.5' ry='15.5'/%3E%3Cpath fill='none' stroke='%23939393' stroke-linecap='round' stroke-width='2.999' d='m21.022 11.001-7.964 9.058m.002-9.057 7.964 9.058'/%3E%3C/svg%3E");
                        left: 0;
                    }
                }
                &.tarteaucitronIsAllowed {
                    .tarteaucitronServiceIpt.tarteaucitronAllow {
                        z-index: 1;
                    }
                    .toggle-outside {
                        background-color: #6c5b51fc;
                    }
                    .toggle-inside {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='31' viewBox='0 0 33 31'%3E%3Cellipse cx='16.5' cy='15.5' fill='%23fff' rx='16.5' ry='15.5'/%3E%3Cpath fill='none' stroke='%2354A659' stroke-linecap='round' stroke-width='2.999' d='m10.333 14.645 5.056 6.315m7.964-11.959L15.39 20.957'/%3E%3C/svg%3E");
                        left: calc(100% - 33px);
                    }
                }
                .tarteaucitronName {
                    flex: 0 1 100%;
                    padding-right: 20px;
                    @include mq(768px,null) {
                        padding-right: 30px;
                    }
                }
                .tarteaucitronReadmoreSeparator {
                    &.hidden {
                        @include visuallyhidden;
                    }
                }
            }
            #tarteaucitronServices_mandatory {
                .tarteaucitronServiceIpt {
                    cursor: default;
                    pointer-events: none;
                }
                .toggle {
                    opacity: 0.5;
                }
            }
            .tarteaucitronHidden {
                display: none;
                position: relative;
            }
        }
    }
}
.tac_activate {
    &_youtube {
        @include abs_cover;
        background-color: $border-account;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.tac_visually-hidden {
    @include visuallyhidden;
}