@import "partials/mixins";
@import "partials/variables";
@import "partials/typography";
@import "partials/functions";
@import "partials/flex-content";
@import "components/rgpd";

*,
*:after,
*:before {
    @include box-sizing(border-box);
}

.visuallyhidden {
    @include visuallyhidden;
}

.clearfix {
    @include clearfix;
}

body {
    @include cabin();
    @include font-size-lh(15, 18);
    color: #181310;
    margin: 0;
}

.container {
    width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.header {
    background-color: #6c5b51fc;
    box-shadow: 0 3px 6px rgba($white, 0.16);
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 5;
    &--logo {
        &--link {
            display: block;
            width: 100%;
            height: 150px;
            @include mobile() {
                height: 100px;
            }
        }
        &--img {
            width: 100%;
            height: 100%;
            @include object('contain');
        }
    }
}
.companies {
    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
        padding-top: 40px;
        @include mobile() {
            margin: 0 50px;
            padding-top: 30px;
        }
    }
}
.company--item {
    flex: 0 0 100%;
    margin: 15px 0;
    @include mobile-up() {
        flex-basis: calc(100% / 3 - 30px);
        margin: 0 15px;
    }
    &--logo {
        box-shadow: 3px 3px 6px rgba($black, 0.16);
        padding: 15px;
        border-radius: 10px;
        background-color: rgba(#b6ae9f, 0.5);
        &--link {
            display: block;
            width: 100%;
            height: 180px;
            @include mobile() {
                height: 100px;
            }
        }
        &--img {
            width: 100%;
            height: 100%;
            @include object('contain');
            transform: scale(0.75);
        }
    }
    &--rs {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        &--item {
            flex: 0 0 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #6c5b51fc;
            margin: 0 5px;
            &--link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.products {
    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
        padding-bottom: 40px;
        @include mobile() {
            margin: 0 50px;
            padding-top: 30px;
        }
    }
}
.product--item {
    flex: 0 0 100%;
    margin: 15px 0;
    @include mobile-up() {
        flex-basis: calc(100% / 3 - 30px);
        margin: 0 15px;
    }
    &--logo {
        box-shadow: 3px 3px 6px rgba($black, 0.16);
        &--link {
            display: block;
            width: 100%;
            height: 180px;
            @include mobile() {
                height: 100px;
            }
        }
        &--img {
            width: 100%;
            height: 100%;
            @include object('cover');
        }
    }
}
.footer {
    background-color: #6c5b51fc;
    box-shadow: 0 -3px 6px rgba($white, 0.16);
    padding: 15px 20px;
    &--legals {
        @include cabin();
        @include font-size-lh(15, 18);
        color: $white;
        text-align: center;
        a {
            @include cabin();
            @include font-size-lh(15, 18);
            color: $white;
            text-decoration: none;
        }
    }
}
.picto-facebook,
.picto-instagram,
.picto-linkedin,
.picto-twitter {
    .cls-1 {
        fill: #fff;
        transition: all 0.3s;
    }
}
.picto-facebook {
    width: 10px;
}
.picto-instagram {
    width: 16px;
}
.picto-linkedin {
    width: 16px;
}
.picto-twitter {
    width: 16px;
}