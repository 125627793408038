$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial($font-weight: 400) {
    font-family: $arial;
    font-weight: $font-weight;
}
@mixin times($font-weight: 400) {
    font-family: $times;
    font-weight: $font-weight;
}
$cabin: 'Cabin', $sans-serif;
@mixin cabin($font-weight: 400) {
    font-family: $cabin;
    font-weight: $font-weight;
}

@mixin cabin12($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(12, 15);
    color: $color;
}
@mixin cabin14($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(14, 15);
    color: $color;
}
@mixin cabin15($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(15, 18);
    color: $color;
}
@mixin cabin16($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(16, 18);
    color: $color;
}
@mixin cabin17($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(17, 20);
    color: $color;
}
@mixin cabin20($font-weight: 400, $color: $black) {
    @include cabin($font-weight);
    @include font-size-lh(20, 24);
    color: $color;
}